import moment from 'moment';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button } from '../../../../components/Button';
import { FileInput } from '../../../../components/FileInput';
import { Input } from '../../../../components/Input';
import { useModal } from '../../../../components/Modal';
import { Textarea } from '../../../../components/Textarea';
import { createPost } from '../../api/createPost';
import { getPosts } from '../../api/getPosts';
import { Post } from '../../types';
import { FeedEdit } from '../FeedEdit';
import { FeedPost } from '../FeedPost';
import './index.scss';

const formatPosts = (posts: Post[]) => {
  return posts.map((params) => <FeedPost key={params.id} {...params} />);
};

export const FeedView: React.FC = () => {
  const [posts, setPosts] = useState<Post[]>([]);

  /*
    This is an area of my code I want to optimize. 
    Manual input state handling. Optimization: Use Form Library!
  */
  const [username, setUsername] = useState('');
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [image, setImage] = useState<string | ArrayBuffer>('');

  const { modal: CreatePostModal, toggle: toggleCreatePostModal } = useModal();

  const handlePosts = () => {
    getPosts().then((response) => {
      const _posts = response.data.sort((a, b) => (
        moment(b.datetime, 'MMDDYYYY:HHmmss').valueOf() -
        moment(a.datetime, 'MMDDYYYY:HHmmss').valueOf()
      ));

      setPosts(_posts);
    });
  };

  const handleImageUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = (e.target.files || [])[0];
    const reader = new FileReader();

    reader.onload = () => {
      if (!reader.result) return;
      setImage(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const handleCreatePost = () => {
    const datetime = moment().format('MMDDYYYY:HHmmss');
    const formattedBody = body.replace(/\n/g, '\\n');

    const payload = {
      username,
      title,
      body: formattedBody,
      datetime,
      image,
      hasImage: !!image,
      likes: 0,
      id: `post:${username}:${datetime}`,
    };

    createPost(payload).then(() => {
      setPosts([payload, ...posts]);
      toggleCreatePostModal();

      setImage('');
      setTitle('');
      setBody('');
      setUsername('');
    });
  };

  useEffect(handlePosts, []);

  const postList = formatPosts(posts);

  const isUsernameSelected = !!localStorage.getItem('username_chosen');

  return (
    <ul className="post-list">
      <FeedEdit onClick={toggleCreatePostModal} />

      {postList}

      <CreatePostModal
        title="Create a Post"
        description="Use the form below to create a post!"
        submitButton={<Button onClick={handleCreatePost}>Post!</Button>}
      >
        {!isUsernameSelected ? (
          <Input
            onChange={(e) => setUsername(e.target.value)}
            placeholder="e.g. IAmTheGreatest"
            label="Username (*)"
          />
        ) : null}
        <Input
          onChange={(e) => setTitle(e.target.value)}
          placeholder="e.g. This is a title..."
          label="Title (*)"
        />
        <Textarea
          onChange={(e) => setBody(e.target.value)}
          placeholder="e.g. This is my post..."
          label="Body (*)"
          className="h-200"
        />
        <FileInput onChange={handleImageUpload} label="Image" />
      </CreatePostModal>
    </ul>
  );
};
