import React, { useCallback, useState } from 'react';
import { Modal, ModalPortal, ModalPortalProp, ModalProp } from './Modal';

export type UseModalResult = {
  toggle: () => void;
  hide: () => void;
};

type ModalCallback = Omit<ModalProp, 'hide'> & ModalPortalProp & { hide?: () => void };

export const useModal = () => {
  const [isShowing, setIsShowing] = useState(false);

  const toggle = useCallback(() => {
    setIsShowing(!isShowing);
  }, [isShowing]);

  const hide = useCallback(() => {
    setIsShowing(false);
  }, []);

  const modalCallback = ({ children, ...props }: ModalCallback) => {
    return (
      <ModalPortal isShowing={props.isShowing || isShowing}>
        <Modal hide={toggle} {...props}>
          {children}
        </Modal>
      </ModalPortal>
    );
  };

  const modal = useCallback(modalCallback, [isShowing, toggle]);

  return { toggle, modal, hide };
};
