import React from 'react';
import profilePlaceholder from '../../../../assets/profile-placeholder.png';
import './index.scss';

type FeedCommentParams = {
  body: string;
  username: string;
}

export const FeedComment: React.FC<FeedCommentParams> = ({ body, username }) => {
  return <li className="feed-comment">
    <div className="profile-image">
      <img src={profilePlaceholder} alt="Profile placeholder" />
    </div>
    
    <div className="comment-body">
      <span className="user-name">{username}</span>
      {body}
    </div>
  </li>;
};
