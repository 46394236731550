import React from 'react';
import { FeedView } from '../components/FeedView';

export const Feed = () => {
  return (
    <>
      <FeedView />
    </>
  );
};
