import React from 'react';
import { Header } from '..';
import './index.scss';

export const PublicLayout: React.FC = ({children}) => {
  return <>
    <Header />
    <div className="content-wrapper">{children}</div>
  </>;
};
