import React from 'react';
import { Route, Routes } from 'react-router';
import { PublicLayout } from '../../../components/PublicLayout';
import { Feed } from './Feed';

export const FeedRoutes: React.FC = () => {
  return <PublicLayout>
    <Routes>
      <Route path="" element={<Feed />} />
    </Routes>
  </PublicLayout>;
};
