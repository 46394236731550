import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import profilePlaceholder from '../../../../assets/profile-placeholder.png';
import { getImage } from '../../api/getImage';
import { FaHeart, FaComment, FaTimes } from 'react-icons/fa';
import './index.scss';
import { likePost } from '../../api/likePost';
import { dislikePost } from '../../api/dislikePost';
import { FeedComments } from '../FeedComments';
import { useModal } from '../../../../components/Modal';

type FeedPostProps = {
  username: string;
  datetime: string;
  body: string;
  hasImage: boolean;
  image?: string;
  likes: number;
  id: string;
};

export const FeedPost: React.FC<FeedPostProps> = ({
  username,
  datetime,
  body,
  hasImage,
  image: preloadedImage,
  likes: preloadedLikes,
  id,
}) => {
  const [image, setImage] = useState(preloadedImage);
  const [likes, setLikes] = useState(preloadedLikes);
  const [liked, setLiked] = useState(false);

  const { modal: CommentsModal, toggle: toggleCommentsModal } = useModal();
  const { modal: ImageModal, toggle: toggleImageModal } = useModal();

  const formattedDatetime = moment(datetime, 'MMDDYYYY:HHmmss').format(
    'MM/DD/YYYY - HH:mm'
  );
  const key = `post:${username}:${datetime}`;

  const formattedBody = body.split(/\\n/).map((item, key) => (
    <p key={key}>
      {item}
      <br />
    </p>
  ));

  const handleImage = useCallback(() => {
    if (!hasImage || preloadedImage) return;
    getImage(key).then((response) => setImage(response.data));
  }, [hasImage, preloadedImage, key]);

  const handleLike = () => {
    if (liked) {
      let likedPosts = JSON.parse(
        localStorage.getItem('cfsm-liked') || '[]'
      ) as string[];
      likedPosts = likedPosts.filter((post) => post !== key);
      localStorage.setItem('cfsm-liked', JSON.stringify(likedPosts));
      dislikePost(key);
    } else {
      const likedPosts = JSON.parse(
        localStorage.getItem('cfsm-liked') || '[]'
      ) as string[];
      likedPosts.push(key);
      localStorage.setItem('cfsm-liked', JSON.stringify(likedPosts));
      likePost(key);
    }

    setLiked(!liked);
    setLikes((n) => (liked ? n - 1 : n + 1));
  };

  const checkLiked = () => {
    const likedPosts = JSON.parse(
      localStorage.getItem('cfsm-liked') || '[]'
    ) as string[];
    if (likedPosts.indexOf(key) > -1) setLiked(true);
  };

  useEffect(handleImage, [handleImage]);
  useEffect(checkLiked, [checkLiked]);

  return (
    <li className="post-card">
      <div className="profile-header">
        <img src={profilePlaceholder} alt="Profile" />
        <div className="profile-info">
          <span className="username">{username}</span>
          <span className="datetime">{formattedDatetime}</span>
        </div>
      </div>

      <div className="post-body">
        {formattedBody}
        {hasImage && <img onClick={toggleImageModal} src={image} alt="Post Banner" />}
      </div>

      <div className="toolbar">
        <span className="likes">{likes} Likes</span>
        <FaHeart className={liked ? 'active' : 'normal'} onClick={handleLike} />
        <FaComment onClick={toggleCommentsModal} />
      </div>

      <CommentsModal className="comments-modal" showButtons={false} title="Comments">
        <FeedComments postId={id} />
        <FaTimes className="close-modal" onClick={toggleCommentsModal} />
      </CommentsModal>

      <ImageModal showButtons={false} className="image-preview-modal">
        <img src={image} alt="Fullscreen" />
        <FaTimes className="close-modal" onClick={toggleImageModal} />
      </ImageModal>
    </li>
  );
};
