import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button } from '../../../../components/Button';
import { Input } from '../../../../components/Input';
import { createComment } from '../../api/createComment';
import { getComments } from '../../api/getComments';
import { Comment } from '../../types';
import { FeedComment } from '../FeedComment';
import './index.scss';

type FeedCommentsProps = {
  postId: string;
};

const formatComments = (comments: Comment[]) => {
  return comments.map((comment) => <FeedComment key={comment.id} {...comment} />);
};

export const FeedComments: React.FC<FeedCommentsProps> = ({ postId }) => {
  const [commentBody, setCommentBody] = useState('');
  const [comments, setComments] = useState<Comment[]>([]);

  const handleCreateComment = () => {
    const datetime = moment().format('MMDDYYYY:HHmmss');

    const commentPayload = {
      username: 'Username Not Defined',
      postId,
      body: commentBody,
      datetime,
      id: `comment:${postId}:ABC:${datetime}`,
    };

    createComment(commentPayload);

    setCommentBody('');
    setComments((current) => [commentPayload, ...current]);
  };

  const handleComments = () => {
    getComments({ postId }).then((response) => {
      const _comments = response.data.sort(
        (a, b) => moment(b.datetime).valueOf() - moment(a.datetime).valueOf()
      );

      setComments(_comments);
    });
  };

  const postList = formatComments(comments);

  useEffect(handleComments, [postId]);

  return (
    <div className="feed-comments">
      <ul className="comment-list">{postList}</ul>

      <div className="comment-input">
        <Input
          value={commentBody}
          onChange={(e) => setCommentBody(e.target.value)}
          placeholder="Share your thoughts about this post..."
        />
        <Button onClick={handleCreateComment}>Send</Button>
      </div>
    </div>
  );
};
