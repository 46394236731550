import React from 'react';
import './index.scss';

export type InputProps = {
  label?: string;
} & JSX.IntrinsicElements['input'];

export const FileInput: React.FC<InputProps> = ({ label, ...props }) => {
  return <div className="input-wrapper">
    {/* Label information */}
    <label htmlFor={props.id || props.name}>{label}</label>

    {/* Input updated with field data and meta information */}
    <input className="text-input" {...props} type="file" />
  </div>;
};
