import React from 'react';
import './index.scss';

type ButtonProps = {
  variant?: 'raw' | 'menu' | 'inverted' | '';
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const Button: React.FC<ButtonProps> = ({
  children,
  variant,
  className,
  ...props
}) => {
  const classNameProp = ['default-button', variant, className].join(' ');

  return (
    <button className={classNameProp} {...props}>
      <span>{children}</span>
    </button>
  );
};

Button.defaultProps = {
  variant: '',
  type: 'button',
};
