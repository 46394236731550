import React from 'react';
import profilePlaceholder from '../../../../assets/profile-placeholder.png';
import './index.scss';

type FeedEditProps = {
  onClick: () => void;
}

export const FeedEdit: React.FC<FeedEditProps> = ({ onClick }) => {
  return <div className="post-card feed-edit">
    <div className="profile-header">
      <img src={profilePlaceholder} alt="Profile" />
    </div>
    <div className="profile-info">
      <button
        onClick={onClick}
        className="create-post-button"
      >
        Click here and share your thoughts with your peers!
      </button>
    </div>
  </div>;
};
