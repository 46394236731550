import React from 'react';
import './index.scss';

export const Header: React.FC = () => {
  return <div className="header-wrapper">
    <ul className="items">
      <li className="logo">Cloudflare - Social Media</li>
    </ul>
  </div>;
};
