import { axios } from '../../../lib/axios';
import { Post } from '../types';

type CreateCommentOptions = {
  datetime: string;
  body: string;
  username: string;
  postId: string;
};

export const createComment = async (params: CreateCommentOptions) => {
  return await axios.post<Post>(`/posts/${params.postId}/comments`, params);
};
