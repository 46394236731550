import { axios } from '../../../lib/axios';
import { Post } from '../types';

export type CreatePostOptions = {
  title: string;
  datetime: string;
  body: string;
  username: string;
};

export const createPost = async (params: CreatePostOptions) => {
  return await axios.post<Post>('/posts', params);
};
