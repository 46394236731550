import React from 'react';
import ReactDOM from 'react-dom';
import { Button } from '../Button';
import './index.scss';

export type ModalPortalProp = {
  isShowing?: boolean;
  children?: React.ReactNode;
};

export type ModalProp = {
  hide: () => void;
  children?: React.ReactNode;
  title?: string;
  description?: string | React.ReactNode;
  submitButton?: React.ReactNode;
  cancelButtonText?: string;
  className?: string;
  showButtons?: boolean;
};

export const Modal: React.FC<ModalProp> = ({
  children,
  submitButton,
  title,
  description,
  hide,
  cancelButtonText,
  className,
  showButtons,
}) => {
  const buttonVariant = submitButton ? 'raw' : undefined;
  const modalBoxClassname = ['modal-box', className].join(' ');

  return (
    <div className="modal-wrapper">
      <div className={modalBoxClassname}>
        {title && <h2 className="title">{title}</h2>}
        {description && <h4 className="description">{description}</h4>}

        <div className="content">{children}</div>

        {showButtons ? (
          <div className="button-footer">
            <Button variant={buttonVariant} onClick={hide}>
              {cancelButtonText}
            </Button>

            {submitButton}
          </div>
        ) : null}
      </div>
    </div>
  );
};

Modal.defaultProps = {
  cancelButtonText: 'Cancel',
  submitButton: null,
  showButtons: true,
};

export const ModalPortal: React.FC<ModalPortalProp> = ({
  isShowing,
  children,
}) => {
  return isShowing
    ? ReactDOM.createPortal(<>{children}</>, document.body)
    : null;
};
