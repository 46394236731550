import React from 'react';
import './index.scss';

type TextareaProps = {
  label?: string;
} & JSX.IntrinsicElements['textarea'];

export const Textarea: React.FC<TextareaProps> = ({ label, ...props }) => {
  const className = ['textarea-input', props.className].join(' ');

  return (
    <div className="input-wrapper">
      {/* Label information */}
      <label htmlFor={props.id || props.name}>{label}</label>

      {/* Input updated with field data and meta information */}
      <textarea {...props} className={className}></textarea>
    </div>
  );
};
